import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {getEntities, createEntity, deleteEntity} from "app/entities/application/application.reducer";
import GenericManageTable from "app/shared/component/generic-manage-table";
import GenericHeader from "app/shared/component/generic-manage-header";
import {faLaptopCode} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ApplicationManage = () => {

  const defaultInput = {
    name: null,
    description: null
  };

  const [inputs, setInputs] = useState(defaultInput);

  const handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(createEntity(inputs));
    await dispatch(getEntities({}));
    await setInputs(defaultInput);
  }

  const dispatch = useAppDispatch();
  const applicationList = useAppSelector(state => state.application.entities);
  const applicationLoading = useAppSelector(state => state.application.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, [])


  const colDef: any = [
    {
      field: 'name',
      headerName: 'Name',
      cellStyle: {textAlign: 'left'}
    },
    {
      field: 'description',
      headerName: 'Description',
      cellStyle: {textAlign: 'left'}
    },
    {
      field: 'id',
      headerName: 'Action',
      maxWidth: 100,
      cellRenderer: (params: any) => {
        return <FontAwesomeIcon icon={faTrash}
                                onClick={(e) => onDelete(e, params)}
                                style={{cursor: 'pointer'}}
                                className="text-danger"
        />
      },
    }
  ];

  const onDelete = async (e, params) => {
    await dispatch(deleteEntity(params.value));
  }

  return (
    <>
      <GenericHeader
        icon={faLaptopCode}
        title={"Manage Applications"}
        size={applicationList.length}
      />

      <hr className="mt-3 mb-2"/>

      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-3">
          <label htmlFor="name" className="form-label">Name</label>
          <input type="text"
                 className={"form-control form-control-sm"}
                 id="name"
                 value={inputs.name || ""}
                 onChange={handleChange}
          />
        </div>
        <div className="col-md-8">
          <label htmlFor="description" className="form-label">Description</label>
          <input type="text"
                 className={"form-control form-control-sm"}
                 id="description"
                 value={inputs.description || ""}
                 onChange={handleChange}
          />
        </div>
        <div className="col-md-1">
          <div className="form-label">&nbsp;</div>
          <button type="submit" className="btn btn-primary btn-sm col-md-12">Add</button>
        </div>
      </form>

      <hr className="mt-3 mb-3"/>

      <GenericManageTable
        colDef={colDef}
        data={applicationList}
      />
    </>
  );
};


export default ApplicationManage;
