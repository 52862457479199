import React, {useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {deleteEntity, getEntity, partialUpdateEntity} from './text-content.reducer';
import {getEntities as appGetEntities} from "app/entities/application/application.reducer";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {confirmAlert} from 'react-confirm-alert';
import GenericHeader from "app/shared/component/generic-manage-header";
import {faLaptopCode} from "@fortawesome/free-solid-svg-icons";
import GenericManageTable from "app/shared/component/generic-manage-table";
import {getEntitiesFilterByCode} from "app/entities/translation/translation.reducer";
import Select from "react-select";
import {ActionMeta, OnChangeValue} from "react-select/dist/declarations/src/types";
import {toast} from "react-toastify";
import {AUTHORITIES} from 'app/config/constants';
import {hasAnyAuthority} from 'app/shared/auth/private-route';
import { getActiveEntities } from "app/entities/language/language.reducer";
import LangWithFlag from "app/shared/component/lang-with-flag";

const TabName = {
  APP: "APP",
  TRANS: "TRANS",
}

const customStyles = {
  input: styles => {
    return {
      ...styles,
      height: '0.8em'
    };
  }
};

export const TextContentItem = ({data, onClose, ...props}) => {

  const dispatch = useAppDispatch();

  const [string, setString] = useState<string>(data.code);
  const [app, setApp] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(TabName.TRANS);
  const [appOptions, setAppOptions] = useState([]);

  const textCodeApplications = useAppSelector(state => state.textContent.entity.applications);
  const textCodeLoading = useAppSelector(state => state.textContent.loading);
  const textCodeTranslations = useAppSelector(state => state.translation.entities);
  const applicationList = useAppSelector(state => state.application.entities);
  const applicationLoading = useAppSelector(state => state.application.loading);
  const languageLookUpMap = useAppSelector(state => state.language.lookUpMap);

  const isDeveloper = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER]));

  const colDefTrans: any = [
    {
      field: 'langId',
      headerName: 'Language',
      cellStyle: {textAlign: 'left'},
      cellRenderer: (params: any) => {
        return <LangWithFlag code={languageLookUpMap[params.value]?.langCode}/>
      }
    },
    {
      field: 'transString',
      headerName: 'Translations',
      cellStyle: {textAlign: 'left'}
    }
  ];

  const colDefApplication: any = [
    {
      field: 'name',
      headerName: 'Name',
      cellStyle: {textAlign: 'left'},
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      cellStyle: {textAlign: 'left'},
      flex: 1
    }
  ];

  useEffect(() => {
    dispatch(getEntitiesFilterByCode(`textContentId.equals=${data.id}`));
    dispatch(appGetEntities({}));
    dispatch(getEntity(data.id));
    dispatch(getActiveEntities({}));
  }, [])

  useEffect(() => {
    const buildAppOptions = applicationList.map(data => {
      return {
        value: data,
        label: data.name,
      };
    });
    setAppOptions(buildAppOptions);
  }, [applicationList])

  const onUpdate = async (e) => {
    e.preventDefault();
    const newTextContent = {
      ...data,
      id: data.id,
      code: string
    }
    await dispatch(partialUpdateEntity(newTextContent));
    // await onClose();
  }

  const onConfirmDel = (e, data) => {
    e.preventDefault();
    confirmAlert({
      title: 'Deleted this code ?',
      message: `Are you sure to do delete this code, all translations from ${Object.keys(data.allTranslations).length} languages will be deleted`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await dispatch(deleteEntity(data.id));
            await onClose();
          }
        },
        {
          label: 'No',
          onClick: async () => {
            // await onClose();
          }
        }
      ]
    })
  }

  const onSelectApp = async (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
    setApp(newValue.value);
  };

  const onAssign = async (e) => {
    e.preventDefault();
    if (app == null) {
      toast.error("Nothing selected !");
      return
    }
    const newTextContent = {
      id: data.id,
      applications: [...textCodeApplications, app]
    }
    await dispatch(partialUpdateEntity(newTextContent));
    await dispatch(getEntity(data.id));
    await setApp(null);
    // await onClose();
  }

  // @ts-nocheck
  return (
    <div>
      <GenericHeader
        icon={faLaptopCode}
        title={"Text Code Detail"}
        size={0}
        meta={false}
      />

      <hr className="mt-3 mb-2"/>

      <form className="row g-3">
        <div className="col-md-2 d-none">
          <label htmlFor="name" className="form-label">ID</label>
          <input type="text"
                 className={"form-control form-control-sm"}
                 id="name"
                 value={data.id}
                 readOnly={true}
          />
        </div>
        <div className="col-md-12">
          <label htmlFor="description" className="form-label">Code</label>
          <input type="code"
                 className={"form-control form-control-sm"}
                 id="description"
                 value={string}
                 onChange={(e) => setString(e.target.value)}
                 readOnly={!isDeveloper}
          />
        </div>
        {isDeveloper && (
          <div className="col-md-12">
            <button className="btn btn-primary btn-sm" onClick={onUpdate} disabled={textCodeLoading} >Update</button>
            <button className="btn btn-danger btn-sm ms-2"
                    onClick={(e) => onConfirmDel(e, data)}
                    disabled={textCodeLoading}
            >Delete</button>
          </div>
        )}
      </form>

      <hr className="mt-3 mb-3"/>

      <div className={"mt-3"}>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == TabName.TRANS ? 'active' : ''} onClick={() => setActiveTab(TabName.TRANS)}>
              Translations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == TabName.APP ? 'active' : ''} onClick={() => setActiveTab(TabName.APP)}>
              Applications
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TabName.APP}>

            {isDeveloper && (
              <form className="row g-3 mt-3">
              <div className="col-md-10">
                <Select
                  isSearchable={true}
                  options={appOptions}
                  classNamePrefix="select"
                  styles={customStyles}
                  onChange={onSelectApp}
                  isLoading={applicationLoading}
                  isDisabled={applicationLoading}
                />
              </div>
              <div className="col-md-2 align-text-bottom">
                <button className="btn btn-primary btn-sm w-100 h-100" onClick={onAssign}>Assign</button>
              </div>
            </form>
           )}

            <GenericManageTable
              colDef={colDefApplication}
              data={textCodeApplications}
            />
          </TabPane>
          <TabPane tabId={TabName.TRANS}>

            <GenericManageTable
              colDef={colDefTrans}
              data={textCodeTranslations}
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default TextContentItem;
