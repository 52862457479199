import translation from 'app/entities/translation/translation.reducer';
import language from 'app/entities/language/language.reducer';
import textContent from 'app/entities/text-content/text-content.reducer';
import application from 'app/entities/application/application.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  translation,
  language,
  textContent,
  application
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
