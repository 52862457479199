import { IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community';

export class CustomFilter implements IFilterComp {
  params!: IFilterParams;
  filterText!: string | null;
  untranslatedCheck!: boolean | null;
  gui!: HTMLDivElement;
  eFilterText: any;
  eUntranslatedCheck: any;

  init(params: IFilterParams) {
    this.params = params;
    this.filterText = null;
    this.eUntranslatedCheck = null;
    this.setupGui(params);
  }

  // not called by AG Grid, just for us to help setup
  setupGui(params: IFilterParams) {
    this.gui = document.createElement('div');
    if (params.colDef.headerName == 'Code') {
      this.gui.innerHTML = `<div style="padding: 10px; width: 200px;">
                <div class="form-group">
                    <label for="filterText">Message Code Filter</label>
                    <input class="form-control mt-1" type="text" id="filterText" placeholder="Code search..."/>
                </div>
            </div>
        `;
    } else if (params.colDef.headerName == 'Applications') {
      this.gui.innerHTML = `<div style="padding: 10px; width: 200px;">
                <div class="form-group">
                    <label for="filterText">Application Filter</label>
                    <input class="form-control mt-1" type="text" id="filterText" placeholder="Application search..."/>
                </div>
            </div>
        `;
    } else {
      this.gui.innerHTML = `<div style="padding: 10px; width: 200px;">
                <div class="form-group">
                    <label for="filterText">Translate Text Filter</label>
                    <input class="form-control mt-1" type="text" id="filterText" placeholder="Full text search..."/>
                </div>
                <div class="form-check" style="padding-top: 10px;">
                    <input type="checkbox" class="form-check-input" id="untranslatedCheck">
                    <label class="form-check-label mt-1" for="untranslatedCheck" style="font-style: italic;">Untranslated</label>
                </div>
            </div>
        `;
    }
    const listener = (event: any) => {
      this.filterText = event.target.value;
      params.filterChangedCallback();
    };

    const listenerUntranslatedCheck = (event: any) => {
      this.untranslatedCheck = event.target.checked;
      params.filterChangedCallback();
    };

    this.eFilterText = this.gui.querySelector('#filterText');
    this.eFilterText.addEventListener('changed', listener);
    this.eFilterText.addEventListener('onfocusout', listener);
    this.eFilterText.addEventListener('paste', listener);
    this.eFilterText.addEventListener('input', listener);

    this.eUntranslatedCheck = this.gui.querySelector('#untranslatedCheck');
    if (this.eUntranslatedCheck) {
      this.eUntranslatedCheck.addEventListener('change', listenerUntranslatedCheck);
    }
  }

  getGui() {
    return this.gui;
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    const { api, colDef, column, columnApi, context } = this.params;
    const { node } = params;

    // make sure each word passes separately, ie search for firstname, lastname
    let passed = true;
    const value = this.params.valueGetter({
      api,
      colDef,
      column,
      columnApi,
      context,
      data: node.data,
      getValue: field => node.data[field],
      node,
    });
    if (this.untranslatedCheck) {
      if (value) {
        passed = false;
      }
    } else {
      const self = this;
      this.filterText
        ?.toLowerCase()
        .split(' ')
        .forEach(filterWord => {
          // Search compare for application
          if (self.params.colDef.headerName == 'Applications') {
            if (!value || value.length === 0 || !value.find(i =>  i.name.toString().toLowerCase().indexOf(filterWord) != -1)) {
              passed = false;
            }
          }
          // Search compare for plain text
          else if (!value || value.toString().toLowerCase().indexOf(filterWord) < 0) {
            passed = false;
          }
        });
    }
    return passed;
  }

  isFilterActive() {
    return (this.filterText != null && this.filterText !== '') || (this.untranslatedCheck != null && this.untranslatedCheck == true);
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }

    return { value: this.filterText };
  }

  setModel(model: any) {
    this.eFilterText.value = model == null ? null : model.value;
  }
}
