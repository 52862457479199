import React from "react";
import {Badge} from "reactstrap";

const color = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
]

export const BadgeArray = ({data}) => {

  if (data.length === 0) {
    return <p className={'text-secondary'}>Empty</p>
  } else {
    return (
      <span className={'text-truncate w-100'}>
        {
          data.map((element) => <Badge key={element.id}
                                       color={color[element.id % color.length]}
                                       pill={true}
                                       className={"me-1"}>{element.name}
            </Badge>
          )
        }
      </span>
    );
  }

};

export default BadgeArray;
