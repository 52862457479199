import {ExportToCsv} from 'export-to-csv';
import axios, {AxiosResponse} from "axios";
import {ITextContent} from "app/shared/model/text-content.model";
import {MultiValue} from "react-select";
import {ITranslation} from "app/shared/model/translation.model";

const baseOptions = {
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: false,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
};

export const exportTextCode = async () => {
  const allTextCodes: AxiosResponse = await axios.get<ITextContent[]>('api/text-contents');
  const exportData = allTextCodes.data.map(data => {
    return ({
      "code": data.code
    })
  });
  exportDataToCSV(exportData, "text_code_" + new Date().getTime());
}

export const exportCurrentDisplay = async (currentLanguage: MultiValue<{
  value: string;
  label: string;
  id: number;
  langId: string;
  langName: string;
}>) => {

  const setIds: Set<number> = new Set();
  currentLanguage.forEach(lan => {
    setIds.add(lan.id);
  });

  const allTrans: AxiosResponse = await axios.get<ITranslation[]>('api/translations');

  const exportData = allTrans.data
    .filter(e => setIds.has(e.langId))
    .map(data => {
      return ({
        "lan": data.langId,
        "code": data.textContent.code,
        "translations": data.transString
      })
    });

  exportDataToCSV(exportData, "export_" + new Date().getTime());
}

export const exportByLanguage = async (languageChoices, includeUntranslated, multiFile) => {

  const map = {};
  let langFull = ""
  languageChoices.forEach(lan => {
    map[lan.id] = lan.langCode;
    langFull = langFull.concat(lan.langCode).concat("_");
  })

  const promises = languageChoices.map(l => axios.get<ITranslation[]>(`api/translations?langId.equals=${l.id}`));
  let resultData = {};
  let listData = {};

  await Promise.allSettled(promises)
    .then(results => results.forEach(async (result) => {
          if (result.status !== 'fulfilled')
            return;

          const url = new URL(result.value.request.responseURL);
          const searchParams = new URLSearchParams(url.search);
          const langId = searchParams.get('langId.equals');

          resultData[langId] = result.value.data.map(data => (
              {
                "language": map[data.langId],
                "code": data.textContent.code,
                "string": data.transString
              }
            )
          );
          listData[langId] = result.value.data.map(data => data.textContent.id);
        }
      )
    );


  // Handle untranslated
  if (includeUntranslated) {
    for (const key of Object.keys(resultData)) {
      const listId = listData[key].join(',');
      const {data} = await axios.get<ITextContent[]>(`/api/text-contents?id.notIn=${listId}`);
      resultData[key] = resultData[key].concat(data.map(data => (
            {
              "language": map[key],
              "code": data.code,
              "string": "UNTRANSLATED"
            }
          )
        )
      );
    }
  }

  const finalResult = [].concat(...Object.values(resultData));

  if (multiFile) {
    for (const lang of languageChoices) {
      const exportData = finalResult
        .filter(s => s.language == lang.langCode)
        .map(s => {
          return ({
            "code": s["code"],
            "string": s["string"]
          })
        }
      );
      await exportDataToCSV(exportData, `export_${lang.langCode}_trans_` + new Date().getTime());
    }

  } else {
    await exportDataToCSV(finalResult, `export_${langFull}trans_` + new Date().getTime());
  }

}

const exportDataToCSV = (data, fileName) => {
  const displayOptions = {
    ...baseOptions,
    filename: fileName
  };
  const csvExporter = new ExportToCsv(displayOptions);
  if (data.length === 0) throw new Error("No translations found")
  csvExporter.generateCsv(data);
}
