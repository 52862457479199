import {useState} from "react";

export default () => {
  let [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  }

  return {open, toggle};
};
