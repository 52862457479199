import axios from "axios";
import {toast} from "react-toastify";

interface ImportRes {
  message?: string;
  complete?: boolean;
  success?: boolean;
}

export const importCheck = async (file, language): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const checkResult = await axios.post<ImportRes>(`/api/import/check?language=${language}`, formData, config);
  if (checkResult.data.success) {
    return Promise.resolve(checkResult.data.message);
  } else {
    return Promise.reject(checkResult.data.message);
  }
}

export const importUpload = async (file, language, strategy) => {

  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return await axios.post<ImportRes>(`/api/import/upload?language=${language}&strategy=${strategy}`, formData, config);
}
