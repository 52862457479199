import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCalendarTimes, faListAlt} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "reactstrap";

export const GenericHeader = ({icon, title, size, meta = true}) => {
  return (
    <>
      <div>
        <span className={'fw-bold fs-4'}><FontAwesomeIcon icon={icon}/> {" "} {title}</span>
      </div>

      {meta && <>
        <hr className="mt-3 mb-3"/>
        <div>
        <span><FontAwesomeIcon icon={faListAlt}/> {"Total items: "}
          <Badge>{size}</Badge>
        </span>
          <span className={"ms-3"}><FontAwesomeIcon icon={faCalendarTimes}/> {"Last update: "}  </span>
        </div>
      </>}
    </>
  );
};

export default GenericHeader;
