import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TextContent from './text-content';
import TextContentDetail from './text-content-detail';
import TextContentUpdate from './text-content-update';
import TextContentDeleteDialog from './text-content-delete-dialog';

const TextContentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TextContent />} />
    <Route path="new" element={<TextContentUpdate />} />
    <Route path=":id">
      <Route index element={<TextContentDetail />} />
      <Route path="edit" element={<TextContentUpdate />} />
      <Route path="delete" element={<TextContentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TextContentRoutes;
