import axios, {AxiosResponse} from "axios";

export interface ReviewRes {
  data?: [];
  revertible?: boolean;
  message?: string;
  language?: string;
  editor?: string;
  timeStamp?: number
}

export interface RevertRes {
  success?: boolean;
  message?: string;
}
export interface HistoryRes {
  id: number;
  entityType: string;
  editor: string;
  endTime: number;
  startTime: number;
  active: boolean;
  language: string;
}



export const reviewRevert = (): Promise<AxiosResponse<ReviewRes>> => {
  return axios.get<ReviewRes>('/api/revert/review');
}

export const doRevert = async (data) => {
  const postData = {
    "data": data
  }
  return await axios.post<RevertRes>(`/api/revert/revert`, postData);
}

export const getHistory = async () => {
  return axios.get<HistoryRes[]>('/api/revert/history');
}

