import React, {useState} from 'react';
import * as Flags from 'country-flag-icons/react/3x2'
import {getCountryCode} from "app/shared/util/string-utils";
import {faCheck, faFileImport, faStarHalfAlt, faVoteYea, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ActiveDisplay = ({value}) => {
  if (value) {
    return <FontAwesomeIcon icon={faCheck} className={"text-success"} />
  } else {
    return <FontAwesomeIcon icon={faXmarkCircle} className={"text-danger"} />
  }
};

export default ActiveDisplay;
