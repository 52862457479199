import React, {useMemo, useRef, useState} from 'react'
import Select from "react-select";
import {toast} from "react-toastify";
import {exportByLanguage} from "app/shared/util/export-utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Flags from "country-flag-icons/react/3x2";
import {getCountryCode} from "app/shared/util/string-utils";
import GenericHeader from "app/shared/component/generic-manage-header";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import {FormGroup, Input, Label} from 'reactstrap';


export const TextContentExport = ({data, onClose, ...props}) => {

  const selectRef = useRef(null);
  const [check, setCheck] = useState({
    untranslated: false,
    multi: false
  });
  const [loading, setLoading] = useState(false);

  const options = useMemo(() => {
    return data.map(eachOption => {
      const Flag = Flags[(getCountryCode(eachOption.langCode))];
      return {
        ...eachOption,
        value: eachOption.langCode,
        label: <>{Flag !== undefined && <Flag style={{"height": "14px"}}/>}
          <code className={"ms-3"}>{`${eachOption.langCode}`}</code>
        </>,
      };
    });

  }, data);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (selectRef.current.getValue().length === 0) {
      toast.warning("No language selected");
      setLoading(false);
    } else {
      const languageChoices = selectRef.current.getValue().map(val => {
        return {id: val.id, langCode: val.langCode}
      });
      exportByLanguage(languageChoices, check.untranslated, check.multi)
        .then(() => toast.success("Export completed !"))
        .catch(e => toast.error(e.message))
        .finally(() => setLoading(false));
    }
  }

  // @ts-nocheck
  return (
    <>
      <GenericHeader icon={faFileExport}
                     title={"Exports by languages"}
                     size={0}
                     meta={false}
      />
      <hr className="mt-3 mb-2"/>

      <form className="row" onSubmit={onSubmit}>
        <div className="col-md-12">
          <label htmlFor="id" className="col-form-label">Select Language:</label>
          <Select
            isMulti
            isLoading={loading}
            isSearchable={true}
            options={options}
            className={"basic-multi-select w-100"}
            placeholder={"Select languages ..."}
            classNamePrefix={"select"}
            maxMenuHeight={80}
            ref={selectRef}
          />
        </div>

        <div className={"col-3 mt-4"}>
          <span className="col-3">Export Options:</span>
        </div>

        <div className={"col-9 mt-4"}>
          <FormGroup check>
            <Input type="checkbox"
                   checked={check.untranslated}
                   onChange={() => setCheck({...check, untranslated: !check.untranslated})}
            />
            <Label check>Include untranslated</Label>
          </FormGroup>

          <FormGroup check>
            <Input type="checkbox"
                   checked={check.multi}
                   onChange={() => setCheck({...check, multi: !check.multi})}
            />
            <Label check>Export to multiple files</Label>
          </FormGroup>

        </div>

        <div className={"col-12 mt-3 text-start"}>
          <button type="submit"
                  className="btn btn-primary"
          >
            {!loading && "Export"}
            {loading && <><FontAwesomeIcon icon="sync" spin={true}/> Processing... </>}
          </button>
        </div>
      </form>
    </>
  );
};

export default TextContentExport;
