import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {getInactiveEntities, updateEntity} from './language.reducer';
import Select from "react-select";
import * as Flags from "country-flag-icons/react/3x2";
import {getCountryCode} from "app/shared/util/string-utils";
import {toast} from "react-toastify";

export const LanguageUpdate = () => {
  const dispatch = useAppDispatch();

  const languageList = useAppSelector(state => state.language.entities);
  const loading = useAppSelector(state => state.language.loading);
  const updating = useAppSelector(state => state.language.updating);
  const updateSuccess = useAppSelector(state => state.language.updateSuccess);

  const ref = useRef(null);

  const options = languageList.map(eachOption => {
    const Flag = Flags[(getCountryCode(eachOption.langCode))];
    return {
      ...eachOption,
      value: eachOption,
      label: <>{Flag !== undefined && <Flag style={{"height": "14px"}}/>}
        <code className={"ms-3"}>{`${eachOption.langCode}`}</code>
      </>,
    };
  });

  const handleClose = () => {
    // navigate('/language');
  };

  useEffect(() => {
    dispatch( getInactiveEntities({}) );
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async (e) => {
    e.preventDefault();
    if (ref.current.getValue().length === 0) {
      toast.warning("No language selected");
    } else {
      const data = ref.current.getValue()[0].value;
      const updateData = { ...data, active: true };
      console.log(updateData);
      await dispatch(updateEntity(updateData));
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="messageStringApp.language.home.createOrEditLabel" data-cy="LanguageCreateUpdateHeading">
            <Translate contentKey="messageStringApp.language.home.createOrEditLabel">Create or edit a Language</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <form onSubmit={saveEntity}>
          <Col md="12">
            <Select
              isLoading={loading}
              isSearchable={true}
              options={options}
              className={"basic-multi-select w-100 mt-3 mb-5"}
              classNamePrefix={"select"}
              maxMenuHeight={150}
              ref={ref}
            />

            <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/language" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
            </Button>
            &nbsp;
            <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
              <FontAwesomeIcon icon="save" />
              &nbsp;
              Active
            </Button>
          </Col>
        </form>
      </Row>
    </div>
  );
};

export default LanguageUpdate;
