import React, {useCallback, useEffect, useRef} from 'react';
import {AgGridReact} from "ag-grid-react";

const containerStyle = {
  width: '100%',
  height: 'calc(40vh)',
};

export const GenericManageTable = ({data, colDef, rowHeight= 25}) => {

  const gridRef = useRef(null);
  const onGridReady = async (params: any) => {
    params.api.sizeColumnsToFit();
  };

  return (<>
    <div style={containerStyle} className="mt-4 ag-theme-alpine">
      <AgGridReact
        rowHeight={rowHeight}
        ref={gridRef as any}
        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
        overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No data founds</span>'}
        rowData={data}
        columnDefs={colDef}
        animateRows={true}
        rowSelection={'multiple'}
        pagination={true}
        paginationAutoPageSize={true}
        className={"mt-2"}
        onGridReady={onGridReady}
      />
    </div>
  </>);
};

export default GenericManageTable;
