import React, {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {createEntity as textContentCreateEntity, getEntities as textContentGetEntities} from './text-content.reducer';
import {getEntities as appGetEntities} from "app/entities/application/application.reducer";
import {faTable} from "@fortawesome/free-solid-svg-icons";
import GenericHeader from "app/shared/component/generic-manage-header";
import GenericManageTable from "app/shared/component/generic-manage-table";
import Select from "react-select";

export const TextContentManage = () => {
  const dispatch = useAppDispatch();
  const textContentList = useAppSelector(state => state.textContent.entities);
  const totalItems = useAppSelector(state => state.textContent.totalItems);
  const applicationList = useAppSelector(state => state.application.entities);
  const applicationLoading = useAppSelector(state => state.application.loading);
  const [code, setCode] = useState(null);
  const [app, setApp] = useState([]);

  const appOptions: any[] = useMemo(() => {
    return applicationList.map(app => {
      return {
        value: app,
        label: app.name
      }
    }, []);
  }, [applicationList]);

  useEffect(() => {
    dispatch(appGetEntities({}));
    dispatch(textContentGetEntities(
        {
          page: 0,
          size: totalItems,
          sort: `id,asc`,
        }
      )
    );

  }, []);

  const colDef: any = [
    {
      field: 'id',
      headerName: 'ID',
      cellStyle: {textAlign: 'left'}
    },
    {
      field: 'code',
      headerName: 'Code',
      cellStyle: {textAlign: 'left'}
    }
  ];

  const onAdd = async (e) => {
    e.preventDefault();
    const newTextCode = {code: code, applications: app};
    await dispatch(textContentCreateEntity(newTextCode));
    await dispatch(textContentGetEntities({}));
    await setCode("");
    await setApp(null);
  }

  return (
    <>
      <GenericHeader
        icon={faTable}
        title={"Manage Text Content"}
        size={textContentList.length}
      />

      <hr className="mt-3 mb-2"/>

      <form className="row g-3 align-items-end">
        <div className="col-md-5">
          <label htmlFor="code" className="form-label">Code</label>
          <input type="text"
                 className={"form-control form-control-sm"}
                 id="code"
                 value={code}
                 onChange={e => setCode(e.target.value)}
          />
        </div>

        <div className="col-md-5">
          <label htmlFor="application" className="form-label">Application</label>
          <Select
            isMulti
            isSearchable={true}
            options={appOptions}
            className={'basic-multi-select w-100'}
            classNamePrefix="select"
            onChange={value => setApp(value.map(v => v.value))}
            isLoading={applicationLoading}
            isDisabled={applicationLoading}
            placeholder={"Select applications ..."}
          />
        </div>

        <div className="col-md-2 position-relative">
          <button type="submit" className="btn btn-primary btn-sm position-absolute bottom-0 w-75"
                  onClick={onAdd}
          >
            Add
          </button>
        </div>
      </form>

      <hr className="mt-3 mb-2"/>

      <GenericManageTable
        colDef={colDef}
        data={textContentList}
      />
    </>
  );
};

export default TextContentManage;
