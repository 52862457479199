import React, {useState} from 'react';
import {PlusSquareFill} from 'react-bootstrap-icons';
import useModals from "app/hooks/useModals";
import Modal from 'react-modal';
import {createEntity as transCreateEntity, deleteEntity as transDeleteEntity} from '../translation/translation.reducer';
import {useAppDispatch} from "app/config/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "60%"
  },
};

export const TextContentCell = ({transString, textContent, language, refresh, ...props}) => {

  const dispatch = useAppDispatch();

  const {open, handleModal} = useModals();
  const [string, setString] = useState<string>();

  const onSubmit = async (e) => {
    e.preventDefault();
    const newTrans = {
      langId: (language.id),
      textContent: {
        id: textContent.id
      },
      transString: string
    }
    await dispatch(transCreateEntity(newTrans));
    await handleModal(false, "Closing modal ...");
    await refresh();
  }

  const onDelete = async (e) => {
    e.preventDefault();
    const {id} = textContent.allTranslations[language.langCode];
    await dispatch(transDeleteEntity(id));
    await refresh();
  }

  // ADD VIEW FOR CELL
  if (transString === undefined) {
    return (<div className="container w-100">
      <div className="row d-flex align-items-baseline">
        <div className="col-md-11 text-left text-muted text-truncate">

        </div>
        <div className="col-md-1 text-right text-success">
          <PlusSquareFill
            style={{cursor: 'pointer'}}
            size={14}
            onClick={() => handleModal(true, "")}
          />
        </div>
      </div>

      {/*MODAL FOR ADD*/}
      <Modal
        isOpen={open}
        contentLabel="Text Modal"
        style={customStyles}
        closeTimeoutMS={200}
      >
        <div className="text-end">
            <span className={'text-danger'}
                  style={{cursor: 'pointer'}}
                  onClick={() => handleModal(false, "Closing modal ...")}>
              X
            </span>
        </div>

        <form onSubmit={onSubmit}>

          <div className="form-group row">
            <label htmlFor="language" className="col-sm-2 col-form-label">Language</label>
            <div className="col-sm-10">
              <input type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="language"
                     value={language.langCode}
              />
            </div>
          </div>

          <div className="form-group row">

            <label htmlFor="Code" className="col-sm-2 col-form-label">Code</label>
            <div className="col-sm-10">
              <input type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="Code"
                     value={textContent.code}/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="string" className="col-sm-2 col-form-label">Content</label>
            <div className="col-sm-10">
              <input type="text"
                     className="form-control"
                     id="string"
                     placeholder="Translation content"
                     onChange={(e) => setString(e.target.value)}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-block w-100 mt-3">Add</button>
        </form>
      </Modal>

    </div>);
  } else {

    // UPDATE AND DELETE VIEW FOR CELL
    return (
      <div className="container w-100">
        <div className="row d-flex align-items-baseline">
          <div className="col-md-11 text-left text-truncate">
            {transString}
          </div>
          <div className="col-md-1 text-right">
            <FontAwesomeIcon icon={faTrash}
                             onClick={(e) => onDelete(e)}
                             style={{cursor: 'pointer'}}
                             className="text-danger"
            />
          </div>
        </div>
      </div>
    );
  }
};
export default TextContentCell;
