import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntities, partialUpdateEntity} from './language.reducer';
import {faCompass} from "@fortawesome/free-solid-svg-icons";
import GenericHeader from "app/shared/component/generic-manage-header";
import GenericManageTable from "app/shared/component/generic-manage-table";
import LangWithFlag from "app/shared/component/lang-with-flag";
import ActiveDisplay from "app/shared/component/active-display";
import Select from "react-select";
import {ActionMeta, OnChangeValue} from "react-select/dist/declarations/src/types";

const customStyles = {
  input: styles => {
    return {
      ...styles,
      height: '0.8em'
    };
  }
};

export const LanguageManage = () => {
  const dispatch = useAppDispatch();
  const languageList = useAppSelector(state => state.language.entities);
  const languageLoading = useAppSelector(state => state.language.loading);

  const [lanOptions, setLanOptions] = useState([]);
  const [lan, setLan] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const colDef: any = [
    {
      field: 'langCode',
      headerName: 'Code',
      cellStyle: {textAlign: 'left'},
      cellRenderer: (params: any) => {
        return <LangWithFlag code={params.value}/>
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      cellStyle: {textAlign: 'left'}
    },
    {
      field: 'active',
      headerName: 'Active',
      sort: 'desc',
      cellRenderer: (params: any) => {
        return <ActiveDisplay value={params.value}/>
      }
    }
  ];

  useEffect(() => {
    const buildLanOptions = languageList.map(data => {
      return {
        value: data,
        label: data.name,
      };
    });
    setLanOptions(buildLanOptions);
  }, [languageList])

  const onSelectLan = async (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
    setLan(newValue.value);
  };

  const onChangeStatus = async (e) => {
    e.preventDefault();
    const newLan = {...lan, active: !lan.active};
    await (dispatch(partialUpdateEntity(newLan)));
    await (dispatch(getEntities({})));
    await setLan(null);
  };


  return (
    <>
      <GenericHeader
        icon={faCompass}
        title={"Manage Languages"}
        size={languageList.length}
      />

      <hr className="mt-3 mb-2"/>

      <form className="row g-3 mt-3">
        <div className="col-md-10">
          <Select
            isSearchable={true}
            options={lanOptions}
            classNamePrefix="select"
            styles={customStyles}
            onChange={onSelectLan}
            isLoading={languageLoading}
            isDisabled={languageLoading}
            ref={selectRef}
          />
        </div>
        <div className="col-md-2 align-text-bottom">
          {lan === null && <button className="btn btn-secondary btn-sm w-100 h-100" disabled={true}>Select Language</button>}
          {lan !== null && lan.active && <button className="btn btn-danger btn-sm w-100 h-100" onClick={onChangeStatus}>Inactive</button>}
          {lan !== null && !lan.active && <button className="btn btn-primary btn-sm w-100 h-100" onClick={onChangeStatus}>Active</button>}
        </div>
      </form>

      <hr className="mt-3 mb-2"/>

      <GenericManageTable
        colDef={colDef}
        data={languageList}
      />

    </>
  );
};

export default LanguageManage;
