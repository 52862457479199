import React, {useMemo, useState} from 'react'
import Select from "react-select";
import {toast} from "react-toastify";
import {importCheck, importUpload} from "app/shared/util/import-utils";
import LangWithFlag from "app/shared/component/lang-with-flag";
import useStateMachine, {t} from "@cassiozen/usestatemachine";
import {Button} from "reactstrap";
import GenericHeader from "app/shared/component/generic-manage-header";
import {faFileImport} from "@fortawesome/free-solid-svg-icons";
import useToggle from "app/hooks/useToggle";
import {JsonViewer} from "@textea/json-viewer";

const ImportForm = ({loading, options, onChange, handleFile}) => {

  return (
    <>
      <label htmlFor="id" className="col-form-label">Select Language:</label>
      <Select
        isLoading={loading}
        isSearchable={true}
        options={options}
        className={"basic-multi-select w-100"}
        classNamePrefix={"select"}
        maxMenuHeight={80}
        onChange={onChange}
      />
      <label htmlFor="formFile" className={"mt-3 form-label"}>Import CSV file</label>
      <input className="form-control" type="file" id="formFile" onChange={handleFile}/>
    </>
  );
}

export const TextContentImport = ({data, onClose, ...props}) => {

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState(null);
  const toggle = useToggle();

  const options = useMemo(() => {
    return data.map(eachOption => {
        return {
          ...eachOption,
          value: eachOption.langCode,
          label: <LangWithFlag code={eachOption.langCode}/>,
        };
      }
    );
  }, data);

  const [machine, send] = useStateMachine({
    schema: {
      context: t<{ askNeed: boolean, userChoice: string, importResult: any }>(),
    },
    context: {askNeed: false, userChoice: "overwrite", importResult: null},
    initial: 'start',
    verbose: true,
    states: {

      // START
      start: {
        effect() {
          setFile(null);
          setLanguage(null);
        },
        on: {
          CHECK: {
            target: 'result',
            guard({context, event}) {
              if (language === null || file === null)
                toast.warning("File must be uploaded and language have to be chose");
              return language !== null && file !== null;
            }
          },
        }
      },

      // CHECK
      check: {
        on: {
          RESULT: 'result',
        },
        effect({setContext}) {
          importCheck(file, language)
            .then(res => setContext(c => (
                {askNeed: res === "YES", userChoice: "skip", importResult: null})
              )
            )
        },
      },

      // RESULT
      result: {
        on: {
          START: 'start'
        },
        effect({setContext}) {
          setLoading(true)
          importUpload(file, language, machine.context.userChoice)
            .then(res => {
                setContext(c => ({...c, importResult: res.data}))
              }
            ).finally(() => setLoading(false))
        },
      },
    },
  });

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files[0]
    setFile(files);
  }

  // @ts-nocheck
  return (
    <>
      <GenericHeader icon={faFileImport}
                     title={"Import files"}
                     size={0}
                     meta={false}
      />
      <hr className="mt-3 mb-2"/>
      <form className="row">
        <div className="col-md-12">
          <ImportForm
            loading={loading}
            options={options}
            onChange={(e) => setLanguage(e.value)}
            handleFile={handleFileSelected}
          />

          <div className={'mt-3'}>
            {machine.value?.includes('start') && (
              <Button color="primary" className={"w-100"} onClick={() => send('CHECK')}>
                Import
              </Button>
            )}

            {machine.value?.includes('check') && (
              <>

                {
                  machine.context.askNeed && <>
                    <div className="alert alert-warning" role="alert">
                      There are already some translations in this language! Do you want to overwrite it?
                      <div className="form-check">
                        <input className="form-check-input"
                               type="checkbox"
                               value=""
                               id="overwrite"
                               onClick={(e) => {
                                 const target = e.target as HTMLInputElement;
                                 machine.context.userChoice = target.checked ? "overwrite" : "skip";
                               }}
                        />
                        <label className="form-check-label" htmlFor="overwrite">
                          Overwrite
                        </label>
                      </div>
                    </div>
                  </>
                }

                {
                  !machine.context.askNeed && <>
                    <div className="alert alert-success" role="alert">
                      There are no conflicts with existed translation, ready to be imported
                    </div>
                  </>
                }
                <Button color="primary" className={"w-100"} onClick={() => send('RESULT')}>
                  Import
                </Button>
              </>
            )}

            {machine.value?.includes('result') && (

              <>
                {loading && <div className={`alert alert-secondary`}
                                 role="alert">
                  Importing ...
                </div>
                }
                {!loading && !!machine.context.importResult &&
                  <>

                    <div className={`alert alert-${machine.context.importResult.success ? 'success' : 'danger'}`}
                         role="alert">
                      {machine.context.importResult.message}
                      <br/>
                      {machine.context.importResult.unImportedCode !== null && <>
                        <a className="alert-link" onClick={() => toggle.toggle()}>Show all unimported code</a>
                      </>}
                    </div>
                    <br/>

                    {toggle.open && <div className={"table-container"} role="alert">
                      {/*<textarea  id="unImportedCode"*/}
                      {/*           className={"form-control"}*/}
                      {/*           rows={7}*/}
                      {/*           readOnly={true}*/}
                      {/*           value={JSON.stringify(machine.context.importResult.unImportedCode)}*/}

                      {/*/>*/}
                      <JsonViewer value={machine.context.importResult.unImportedCode}
                                  enableClipboard={true}
                                  rootName={false}
                                  displayDataTypes={false}
                                  displaySize={false}
                                  quotesOnKeys={false}
                      />
                    </div>}

                  </>
                }
                <Button color="primary" className={"w-100"} onClick={() => send('START')}>
                  Upload again
                </Button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default TextContentImport;
