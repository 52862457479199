import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {faFileExport, faFileImport, faTasksAlt} from '@fortawesome/free-solid-svg-icons'
import {Dropdown} from 'react-bootstrap';
import {exportTextCode} from "app/shared/util/export-utils";
import {useAppSelector} from 'app/config/store';
import {AUTHORITIES} from 'app/config/constants';
import {hasAnyAuthority} from 'app/shared/auth/private-route';

export const TextContentPanel = ({refresh, loading, handleModal, buttonAction}) => {

  const isDeveloper = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER]));

  // @ts-ignore
  return (
    <>
      <button type="button" className="btn btn-primary rounded w-100" onClick={refresh}
              disabled={loading}>
        <FontAwesomeIcon icon="sync" spin={loading}/>
      </button>

      <Dropdown className={`mt-1 ${loading && 'drop-down-disabled'} d-inline-block`}>
        <Dropdown.Toggle><FontAwesomeIcon icon={faFileExport}/></Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => exportTextCode()}>Export all text code</Dropdown.Item>
          <Dropdown.Item onClick={() => handleModal(true, buttonAction.EXPORT)}>Export by language</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>


      <Dropdown className={`mt-1 ${loading && 'drop-down-disabled'} d-inline-block`}>
        <Dropdown.Toggle><FontAwesomeIcon icon={faFileImport}/>{" "}</Dropdown.Toggle>
        <Dropdown.Menu className={"w-100"}>
          <Dropdown.Item onClick={() => handleModal(true, buttonAction.IMPORT)}>Import csv</Dropdown.Item>
          <Dropdown.Item onClick={() => handleModal(true, buttonAction.REVERT)}>Import revert</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {isDeveloper && (
      <Dropdown className={`mt-1 ${loading && 'drop-down-disabled'} d-inline-block`}>
        <Dropdown.Toggle><FontAwesomeIcon icon={faTasksAlt}/>{" "}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleModal(true, buttonAction.MANAGE_LANGUAGE)}>Manage Language</Dropdown.Item>
          <Dropdown.Item onClick={() => handleModal(true, buttonAction.MANAGE_TEXT_CODE)}>Manage Text Code</Dropdown.Item>
          <Dropdown.Item onClick={() => handleModal(true, buttonAction.MANAGE_APPLICATION)}>Manage Application</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      )}

    </>
  );
};

export default TextContentPanel;
