import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITextContent } from 'app/shared/model/text-content.model';
import { getEntities as textContentGetEntities } from './text-content.reducer';
import { getActiveEntities as lanContentGetEntities } from '../language/language.reducer';
import { getEntities as transGetEntities, partialUpdateEntity as transPartialUpdateEntity } from '../translation/translation.reducer';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Select, { MultiValue } from 'react-select';
import TextContentCell from 'app/entities/text-content/text-content-cell';
import useModals from 'app/hooks/useModals';
import TextContentUpdate from 'app/entities/text-content/text-content-update';
import LanguageUpdate from 'app/entities/language/language-update';
import TextContentItem from 'app/entities/text-content/text-content-item';
import TextContentExport from 'app/entities/text-content/text-content-export';
import TextContentImport from 'app/entities/text-content/text-content-import';
import { CustomFilter } from 'app/shared/component/custom-filter';
import LangWithFlag from "app/shared/component/lang-with-flag";
import ApplicationCell from "app/entities/application/application-cell";
import TextContentPanel from "app/entities/text-content/text-content-panel";
import ApplicationManage from "app/entities/application/application-manage";
import LanguageManage from "app/entities/language/language-manage";
import TextContentManage from "app/entities/text-content/text-content-manage";
import CloseButton from 'react-bootstrap/CloseButton';
import GenericHeader from "app/shared/component/generic-manage-header";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import TextContentRevert from "app/entities/text-content/text-content-revert";

const containerStyle = {
  width: '100%',
  height: 'calc(100vh - 265px)',
};

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -45%)',
    width: '70%',
    maxHeight: '85%'
  },
};

const buttonAction = {
  MANAGE_TEXT_CODE: 'MANAGE_TEXT_CODE',
  MANAGE_LANGUAGE: 'MANAGE_LANGUAGE',
  MANAGE_APPLICATION: 'MANAGE_APPLICATION',
  EDIT_TEXT_CODE: 'EDIT_TEXT_CODE',
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT',
  REVERT: 'REVERT',
};

export const TextContent = () => {

  // CONST
  const baseColDef: any = [
    {
      field: 'code',
      headerName: 'Code',
      filter: CustomFilter,
      cellStyle: { textAlign: 'left' },
      pinned: 'left',
      minWidth: 300,
      cellRenderer: (params: any) => {
        return (
          <p
            className={'text-truncate text-left w-100 text-primary clickable'}
            onClick={() =>
              handleModal(true, buttonAction.EDIT_TEXT_CODE, {
                textContent: params.data,
              })
            }
          >
            {params.value}
          </p>
        );
      },
    },
    {
      field: 'applications',
      headerName: 'Applicatons',
      filter: CustomFilter,
      cellStyle: { textAlign: 'left' },
      pinned: 'left',
      resizable: true,
      maxWidth: 450,
      wrapText:true,
      // autoHeight: true,
      cellRenderer: (params: any) => {
        return <ApplicationCell data={params.value} />
      },
    },
  ];


  // REF
  const gridRef = useRef();
  const selectRef = useRef(null);

  // HOOKS
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { open: openModal, data: dataModal, context: contextModal, handleModal } = useModals();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const textContentList = useAppSelector(state => state.textContent.entities);
  const textLoading = useAppSelector(state => state.textContent.loading);
  const totalItems = useAppSelector(state => state.textContent.totalItems);

  const lanLoading = useAppSelector(state => state.language.loading);
  const langList = useAppSelector(state => state.language.entities);
  const [languageOptions, setLanguageOptions]: any = useState([]);

  // STATE
  const [colDefs, setColDefs] = useState<{}[]>(baseColDef);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      minWidth: 200,
    }),
    []
  );

  const getAllEntities = () => {
    dispatch(
      textContentGetEntities({
        page: paginationState.activePage - 1,
        size: totalItems,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
    dispatch(lanContentGetEntities({}));
    dispatch(transGetEntities({}));
  };

  const refreshSortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    const buildLanguageOptions = langList.map(data => {
      return {
        ...data,
        value: data.langCode,
        label: <LangWithFlag code={data.langCode} />,
      };
    });
    setLanguageOptions(buildLanguageOptions);
    if (selectRef.current.getValue().length === 0) {
      selectRef.current.setValue(buildLanguageOptions.filter(i => i.langCode === 'en_US'));
    }
  }, [lanLoading]);

  useEffect(() => {
    refreshSortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const onSelectLanguage = async (
    newValue: MultiValue<{
      value: string;
      label: string;
      id: number;
      langId: string;
      langName: string;
    }>
  ) => {
    await handleSelectLan(newValue);
  };

  const onGridReady = (params: any) => {
    handleSelectLan(selectRef.current.getValue());
  };

  const handleSelectLan = (
    languages: MultiValue<{
      value: string;
      label: string;
      id: number;
      langId: string;
      langName: string;
    }>
  ) => {
    const newColDef = [...baseColDef];
    languages.forEach(newLan => {
      newColDef.push({
        field: `allTranslations.${newLan.value}.transString`,
        headerName: newLan['name'],
        filter: CustomFilter,
        flex: 4,
        minWidth: 350,
        editable: params => params.data.allTranslations[`${newLan.value}`] !== undefined,
        onCellValueChanged: event => onEditCell(newLan, event),
        cellRenderer: params => (
          <TextContentCell
            transString={params.value}
            textContent={params.data}
            language={newLan}
            refresh={refreshSortEntities}
          />
        ),
      });
    });
    setColDefs(newColDef);
  };

  const onEditCell = async (language, event) => {
    let newTrans = {};
    // Update new Trans
    if (event.data.allTranslations[language.langCode] !== undefined) {
      const id = event.data.allTranslations[language.langCode].id;
      newTrans = {
        id: id,
        transString: event.newValue,
      };
      await dispatch(transPartialUpdateEntity(newTrans));
    }
    refreshSortEntities();
  };

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape' || event.keyCode === 27 && openModal )
      handleModal(false, 'Closing modal ...')
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);
    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [escFunction]);

  // @ts-ignore
  return (
    <div>
      <div id="text-content-heading" data-cy="TextContentHeading">

        <GenericHeader
          icon={faLanguage}
          size={textContentList.length}
          title={"Translation Management"}
          meta={false}
        />

        <div className={'row mt-1'}>
          <div className="col-12 d-flex justify-content-start align-items-center">
            <Select
              isMulti
              isSearchable={true}
              options={languageOptions}
              className={'basic-multi-select w-100'}
              classNamePrefix="select"
              onChange={onSelectLanguage}
              defaultValue={languageOptions[0]}
              ref={selectRef}
              isLoading={lanLoading}
              isDisabled={lanLoading}
              placeholder={"Select languages ..."}
            />
          </div>
        </div>
      </div>

      <div className={'row'}>
        <div className={'text-center'} style={{ width: '5%' }}>
          <div className="btn-group-vertical mt-4 pr-1 text-start" role="group" aria-label="Button group with nested dropdown">

            <TextContentPanel
              refresh={refreshSortEntities}
              handleModal={handleModal}
              loading={textLoading}
              buttonAction={buttonAction}
            />

          </div>
        </div>

        <div className={'col-11'} style={{ width: '95%' }}>
          <div style={containerStyle} className="mt-4 ag-theme-alpine">
            <AgGridReact
              overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
              overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No data founds</span>'}
              ref={gridRef as any}
              rowData={structuredClone(textContentList)}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection={'multiple'}
              onGridReady={onGridReady}
              pagination={true}
              paginationAutoPageSize={true}
            />
          </div>

          {/*FOR MODAL*/}
          <div>
            <Modal isOpen={openModal} contentLabel="Text Cell Modal" style={modalStyles} closeTimeoutMS={100}>
              <div className="text-end text-end float-right d-flex p-1" style={{ float: 'right' }}>
                <CloseButton
                  aria-label="Close"
                  onClick={() => {
                    handleModal(false, 'Closing modal ...');
                    refreshSortEntities();
                  }}
                />
              </div>

              {dataModal === buttonAction.MANAGE_TEXT_CODE && (
                <TextContentManage />
              )}

              {dataModal === buttonAction.MANAGE_LANGUAGE && (
                <LanguageManage />
              )}

              {dataModal === buttonAction.EDIT_TEXT_CODE && (
                <TextContentItem
                  data={contextModal['textContent']}
                  onClose={() => {
                    handleModal(false, 'Closing modal ...');
                    refreshSortEntities();
                  }}
                />
              )}

              {dataModal === buttonAction.EXPORT && (
                <TextContentExport
                  data={languageOptions}
                  onClose={() => {
                    handleModal(false, 'Closing modal ...');
                  }}
                />
              )}

              {dataModal === buttonAction.IMPORT && (
                <TextContentImport data={languageOptions} onClose={() => handleModal(false, 'Closing modal ...')} />
              )}

              {dataModal === buttonAction.REVERT && (
                <TextContentRevert onClose={() => handleModal(false, 'Closing modal ...')} />
              )}

              {dataModal === buttonAction.MANAGE_APPLICATION && (
                <ApplicationManage />
              )}

            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextContent;
