import {useState} from "react";

export default () => {
  let [open, setOpen] = useState(false);
  let [data, setData] = useState("Modal Content");
  let [context, setContext] = useState({});

  const handleModal = (isOpen : boolean, data : any, context ? : {} ) => {
    setOpen(isOpen);
    setData(data);
    setContext(context);
  }

  return {open, data, context, handleModal};
};
